import { filterAtomsToHydrateCallback } from '@/consts/atomHydration';
import { filterAtomClearDefaults } from '@/consts/defaults/filterAtomDefaults';
import { GameType, GameRangeFilterOption } from '@/types/filters';
import { PeriodRange, FormattedGameWithRels, FormattedGameWithTeam } from '@/types/game';
import { GroupedOptions, KeysToCamelCase } from '@/types/generic';
import { ConfigFilterKey, ConfigFilterSet } from '@/types/userConfigs';
import { CycleWithRels, Cycle } from '@statsbomb/parachute-types';
import dayjs from 'dayjs';
import { isEqual } from 'lodash/fp';

export const filterGamesByGameType = (games: FormattedGameWithTeam[], gameType: GameType) => {
  if (gameType !== 'HOME_AWAY') {
    return games.filter(({ team: { teamId }, game: { homeTeamId, awayTeamId } }) =>
      gameType === 'HOME' ? teamId === homeTeamId : teamId === awayTeamId,
    );
  }
  return games;
};

export const filterGamesByDateRange = (games: FormattedGameWithRels[], startDate?: string, endDate?: string) => {
  if (!startDate && !endDate) return games;

  return games.filter(({ game: { date } }) => {
    const startDateFormat = dayjs(startDate);
    const endDateFormat = dayjs(endDate);
    const gameDateFormat = dayjs(date);

    if (startDate && !endDate) return gameDateFormat >= startDateFormat;
    if (!startDate && endDate) return gameDateFormat <= endDateFormat;
    return gameDateFormat >= startDateFormat && gameDateFormat <= endDateFormat;
  });
};

export const filterGamesByCompetition = (games: FormattedGameWithRels[], competitionIds: number[]) => {
  if (!competitionIds.length) return games;

  return games.filter(({ cycle: { competitionId } }) => competitionIds.includes(competitionId));
};

export const filterGamesBySeason = (games: FormattedGameWithRels[], seasonIds: number[]) => {
  if (!seasonIds.length) return games;

  return games.filter(({ cycle: { seasonId } }) => seasonIds.includes(seasonId));
};

export const filterGamesByTeam = (games: FormattedGameWithTeam[], teamIds: number[]) => {
  if (!teamIds.length) return games;

  return games.filter(({ team: { teamId } }) => teamIds.includes(teamId));
};

export const pipeFilterFunctions =
  <T>(...funcs: Function[]) =>
  (data: T[]) =>
    funcs.reduce((acc, func) => func(acc), data);

export const filterGamesBySelectedGameRange = (
  games: FormattedGameWithRels[],
  selectedGameRange: GameRangeFilterOption,
  periodRange: PeriodRange,
  dateRangeStart?: string,
  dateRangeEnd?: string,
) => {
  if (selectedGameRange.key === 'period') {
    const lastXGamesValue = -periodRange.value;
    return games.slice(lastXGamesValue);
  }

  return filterGamesByDateRange(games, dateRangeStart, dateRangeEnd);
};

export const filterUniqueByProperty = <T, K extends keyof T>(property: K, arr: T[]): T[] =>
  arr.reduce<T[]>((acc, cur) => (acc.some(item => item[property] === cur[property]) ? acc : [...acc, cur]), []);

export const getFilterCount = (filters: Partial<ConfigFilterSet>) =>
  Object.entries(filters).reduce((acc, [key, value]) => {
    // GameRange never counts as that's just a toggle to decide if we're showing the
    // date range or period (last 5, last 10 etc) options
    if (key === 'gameRange') return acc;
    const filterKey = key as ConfigFilterKey;
    const defaultValue = filterAtomClearDefaults[filterKey];
    const hydrateCallback = filterAtomsToHydrateCallback[filterKey];
    // This shouldn't happen, but while reviewing someone had an issue here with a bad filter config
    // so adding this to prevent the whole thing breaking.
    if (!hydrateCallback) {
      // eslint-disable-next-line no-console
      console.error(`unable to find hydrateCallback for filter definition key: ${filterKey}`);
      return acc;
    }
    const hydratedValue = hydrateCallback(value);
    // We only want to count saved filters that are not default values
    return isEqual(hydratedValue, defaultValue) ? acc : acc + 1;
  }, 0);

export const filterCyclesWithRelsByCompetition = (
  cycles: KeysToCamelCase<CycleWithRels>[],
  competitionIds: number[],
) => {
  if (!competitionIds.length) return cycles;

  return cycles.filter(({ competition: { competitionId } }) => competitionIds.includes(competitionId));
};

export const filterCyclesBy = (cycles: Cycle[], selectedIds: number[], key: 'competition_id' | 'season_id') => {
  if (!selectedIds.length) return cycles;

  return cycles.filter(cycle => selectedIds.includes(cycle[key]));
};

export const filterGroupedOptions = (groupedOptions: GroupedOptions<string>[], searchValue: string) =>
  groupedOptions
    .map(group => ({
      ...group,
      options: group.options.filter(option => option.label.toLowerCase().includes(searchValue.toLowerCase().trim())),
    }))
    .filter(({ options }) => options.length);
