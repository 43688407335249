import { Suspense, useState } from 'react';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Checkbox,
  Modal,
  ModalFooter,
  ModalHeader,
  SearchField,
  Typography,
} from '@statsbomb/kitbag-components';
import { ColumnGroup } from '@/types/table';
import { isMetricFiltersModalOpenAtom } from '@/atoms/dataLocker/player/modal';
import { tableColumnGroupsAtom } from '@/atoms/queries/tableColumns';
import { toggleValue } from '@/utils/array';
import { noop } from '@/utils/noop';
import { ErrorBoundary } from '@sentry/react';
import { GeneralErrorBoundaryFallback } from '@/components/errorBoundary/ErrorBoundaryFallback';
import { useGroupedOptions } from '@/hooks/useGroupedOptions';
import { filterGroupedOptions } from '@/utils/filters';
import { selectedMetricsAtom } from '@/atoms/dataLocker/metrics';
import { useTheme } from 'styled-components';

const MetricFiltersModalBase = ({ metricGroups = [] }: { metricGroups?: ColumnGroup[] }) => {
  const { t } = useTranslation(['general', 'filters', 'table']);
  const theme = useTheme();
  const isTouch = theme.mqMatch('md');
  const setIsOpen = useSetAtom(isMetricFiltersModalOpenAtom);
  const [searchValue, setSearchValue] = useState('');
  const [playerDataLockerSelectedMetrics, setPlayerDataLockerSelectedMetrics] = useAtom(selectedMetricsAtom);
  const [modalVisibleMetrics, setModalVisibleMetrics] = useState<string[]>(playerDataLockerSelectedMetrics);

  const sortedTranslatedMetricGroups = useGroupedOptions(metricGroups);

  const filteredMetricGroups = filterGroupedOptions(sortedTranslatedMetricGroups, searchValue);
  const filteredMetricsCount = filteredMetricGroups.flatMap(group => group.options).length;

  const toggleMetric = (metricKey: string) => setModalVisibleMetrics(toggleValue(metricKey, modalVisibleMetrics));

  const onConfirm = () => {
    setPlayerDataLockerSelectedMetrics(modalVisibleMetrics);
    setIsOpen(false);
  };

  return (
    <Modal id="metric-filters-modal" isOpen onCancel={() => setIsOpen(false)} onConfirm={onConfirm}>
      <ModalHeader id="metric-filters-modal">{t('addMetricFilter', { ns: 'filters' })}</ModalHeader>
      <div className="w-[320px] md:w-[480px] h-[420px] overflow-y-auto p-4 space-y-4">
        <div className="w-full [&>div]:w-full">
          <SearchField
            clearTitle={t('clear')}
            searchIconTitle={t('search')}
            onClear={() => setSearchValue('')}
            onClick={noop}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value)}
            placeholder={t('metricSearchPlaceholder', { ns: 'filters' })}
            value={searchValue}
          />
        </div>
        {searchValue.trim() && (
          <Typography variant="headingSmall">
            {t('searchSummary', { ns: 'table', count: filteredMetricsCount, searchValue })}
          </Typography>
        )}
        <div className="space-y-4">
          {filteredMetricGroups.map(group => {
            const numberOfOptions = group.options.length;
            const gridRows = isTouch ? numberOfOptions : Math.ceil(numberOfOptions / 2);

            return (
              <div className="flex flex-col gap-2" key={group.header}>
                {group.header && <Typography variant="headingMedium">{group.header}</Typography>}
                <div
                  className="grid grid-flow-col grid-cols-1 md:grid-cols-2 gap-2 pl-2"
                  style={{ gridTemplateRows: `repeat(${gridRows}, auto)` }}
                >
                  {group.options.map(({ label, value }) => {
                    const isChecked = modalVisibleMetrics.includes(value);
                    return (
                      <Checkbox
                        key={value}
                        checked={isChecked}
                        id={value}
                        label={label}
                        onChange={() => toggleMetric(value)}
                        size="small"
                        withLabel
                      />
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <ModalFooter>
        <Button onClick={() => setIsOpen(false)} variant="ghost">
          {t('cancel')}
        </Button>
        <Button onClick={onConfirm} variant="primary" isDisabled={!modalVisibleMetrics.length}>
          {t('apply')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const MetricFiltersModalWithData = () => {
  const columnGroups = useAtomValue(tableColumnGroupsAtom);
  // filter out non metric values (player height, age, etc.)
  const metricGroups = columnGroups.filter(group => group.header.translationKey !== 'player');

  return <MetricFiltersModalBase metricGroups={metricGroups} />;
};

export const MetricFiltersModal = () => {
  const isMetricFiltersModalOpen = useAtomValue(isMetricFiltersModalOpenAtom);

  return (
    isMetricFiltersModalOpen && (
      <ErrorBoundary fallback={<GeneralErrorBoundaryFallback />}>
        <Suspense fallback={<MetricFiltersModalBase />}>
          <MetricFiltersModalWithData />
        </Suspense>
      </ErrorBoundary>
    )
  );
};
