import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TitleSettings, WidgetVisType } from '@/types/widget';
import { useRadarTemplateName } from '@/hooks/useRadarTemplateName';
import { Card, CardHeader, Badge, Typography } from '@statsbomb/kitbag-components';
import { COMING_SOON_WIDGETS, HEADING_ONLY_NAVIGABLE_WIDGETS } from '@/consts/widget';
import { useAtomValue } from 'jotai';
import { unwrappedGameTeamNameAtom } from '@/atoms/game/game';

interface DashboardWidgetProps {
  children: React.ReactNode;
  navigationPath: string;
  vis: WidgetVisType;
  titleSettings?: TitleSettings;
}

interface DashboardWidgetWrapperProps {
  children: React.ReactNode;
  navigationPath: string;
  hasHeadingOnlyNavigation: boolean;
}

const WidgetWrapper = ({ navigationPath, hasHeadingOnlyNavigation, children }: DashboardWidgetWrapperProps) =>
  navigationPath && !hasHeadingOnlyNavigation ? (
    <Link to={navigationPath} className="[&_header]:underline [&:not(:hover)_header]:opacity-sb-secondary">
      {children}
    </Link>
  ) : (
    children
  );

const WidgetHeader = ({ navigationPath, hasHeadingOnlyNavigation, children }: DashboardWidgetWrapperProps) =>
  navigationPath && hasHeadingOnlyNavigation ? (
    <Link to={navigationPath} className="underline [&:not(:hover)]:opacity-sb-secondary">
      <CardHeader>{children}</CardHeader>
    </Link>
  ) : (
    <CardHeader>{children}</CardHeader>
  );

const useGetWidgetTitle = (radarTemplateName: string, vis: WidgetVisType, titleSettings?: TitleSettings) => {
  const { t } = useTranslation('visualisation');
  const gameTeamName = useAtomValue(unwrappedGameTeamNameAtom);
  const teamName = gameTeamName ? ` - ${gameTeamName}` : '';

  if (titleSettings) {
    const { title } = titleSettings;
    const formattedTitle = titleSettings.translate ? t(title) : title;
    return `${teamName}${formattedTitle}`;
  }

  return `${t(vis)}${teamName}${vis === 'radar' ? ` - ${radarTemplateName}` : ''}`;
};

export const DashboardWidget = ({ vis, children, navigationPath, titleSettings }: DashboardWidgetProps) => {
  const { t } = useTranslation('general');
  const radarTemplateName = useRadarTemplateName();

  const title = useGetWidgetTitle(radarTemplateName, vis, titleSettings);
  const hasHeadingOnlyNavigation = HEADING_ONLY_NAVIGABLE_WIDGETS.includes(vis);
  const isComingSoon = COMING_SOON_WIDGETS.includes(vis);

  return (
    <WidgetWrapper navigationPath={navigationPath} hasHeadingOnlyNavigation={hasHeadingOnlyNavigation}>
      <Card>
        <WidgetHeader navigationPath={navigationPath} hasHeadingOnlyNavigation={hasHeadingOnlyNavigation}>
          <Typography variant="headingMedium" as="h3" className="w-full flex items-center gap-2">
            <div className="flex-1 truncate" title={title}>
              {title}
            </div>
            <div>
              {isComingSoon && (
                <Badge variant="subtle" hasIcon={false}>
                  {t('inDevelopment')}
                </Badge>
              )}
            </div>
          </Typography>
        </WidgetHeader>
        {children}
      </Card>
    </WidgetWrapper>
  );
};
