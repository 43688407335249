import { atom } from 'jotai';
import { unwrap } from 'jotai/utils';
import { objSnakeToCamel } from '@/utils/queries';
import { rawFilteredPlayerInfoAtom } from '../queries/players';

export const playerInfoAtom = atom(async get => {
  const playerAtom = await get(rawFilteredPlayerInfoAtom);
  const formattedPlayer = objSnakeToCamel(playerAtom.data[0]);

  return formattedPlayer;
});

export const unwrappedPlayerInfoAtom = unwrap(playerInfoAtom, prev => prev || undefined);

export const selectedPlayerNameAtom = atom(async get => {
  const playerInfo = await get(rawFilteredPlayerInfoAtom);
  const [playerInfoData] = playerInfo.data;

  if (!playerInfoData) return '';

  return playerInfoData.display_name;
});

export const unwrappedSelectedPlayerNameAtom = unwrap(selectedPlayerNameAtom, prev => prev || '');
