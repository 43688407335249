import { unwrappedFilterSetsAtom } from '@/atoms/queries/userConfigs';
import { filterAtomsToHydrate, filterAtomsToHydrateCallback } from '@/consts/atomHydration';
import { Nullable } from '@/types/generic';
import { atom } from 'jotai';
import { ConfigFilterKey } from '@/types/userConfigs';
import { atomWithReset } from 'jotai/utils';
import { resetAllFiltersAtom } from './resetFilters';
import { filterChangesToApplyAtom, lastAppliedFiltersAtom } from './persistedFilters';
import { playerAggsBaseFiltersAtom, playerAggsFilterParamsAtom } from '../queries/player/playerAggData';

export const selectedFilterSetIdAtom = atom<Nullable<string>>(null);

export const selectedFilterSetAtom = atom(get => {
  const filterSets = get(unwrappedFilterSetsAtom);
  const selectedFilterSetId = get(selectedFilterSetIdAtom);
  if (!filterSets || !selectedFilterSetId) return null;

  return filterSets.find(config => config.configId === selectedFilterSetId) || null;
});

export const loadSelectedFilterSetAtom = atom(null, (get, set) => {
  const selectedFilterSet = get(selectedFilterSetAtom);

  if (selectedFilterSet) {
    set(resetAllFiltersAtom);
    Object.entries(selectedFilterSet.definition.filters).forEach(([key, value]) => {
      const filterKey = key as ConfigFilterKey;

      const atomToSet = filterAtomsToHydrate()[filterKey];
      const atomValueToSet = filterAtomsToHydrateCallback[filterKey](value);

      set(atomToSet, atomValueToSet);
    });

    // this auto applies the filter changes on player data locker to avoid having to click apply
    const filterChangesToApply = get(filterChangesToApplyAtom);
    const playerAggsFilterParams = get(playerAggsFilterParamsAtom);
    set(lastAppliedFiltersAtom, filterChangesToApply);
    set(playerAggsBaseFiltersAtom, playerAggsFilterParams);
  }
});

export const filterSetNameAtom = atomWithReset('');

export const isSaveFilterModalOpenAtom = atom(false);
export const isLoadFilterSetModalOpenAtom = atom(false);

export const filterSetIdToDeleteAtom = atomWithReset<string | null>(null);
