import { filterAtomsToHydrate, filterAtomsToHydrateCallback } from '@/consts/atomHydration';
import { filterAtomClearDefaults, filterAtomDefaults } from '@/consts/defaults/filterAtomDefaults';
import { ConfigFilterKey, ConfigGameFilterKey } from '@/types/userConfigs';
import { atom } from 'jotai';
import { lastAppliedFiltersAtom } from './persistedFilters';

export const resetGameRangeFiltersAtom = atom(null, (_, set) => {
  const filtersToReset: ConfigGameFilterKey[] = ['periodRange', 'startDate', 'endDate', 'deselectedGameIds'];
  filtersToReset.forEach(key => set(filterAtomsToHydrate()[key], filterAtomDefaults[key]));
});

export const resetAllFiltersAtom = atom(null, (_, set) => {
  const filtersToReset = Object.keys(filterAtomDefaults) as (keyof typeof filterAtomDefaults)[];
  filtersToReset.forEach(key => set(filterAtomsToHydrate()[key], filterAtomDefaults[key]));
});

export const clearFiltersAtom = atom(null, (_, set, filtersToClear: (keyof typeof filterAtomClearDefaults)[]) => {
  filtersToClear.forEach(key => set(filterAtomsToHydrate()[key], filterAtomClearDefaults[key]));
});

/* this is used in the filter "Apply" approach, so that when a user clicks cancel
it updates the selected filters back to the state the filters were in the last time the 
user had clicked "Apply" */
export const resetFiltersToLastAppliedFiltersAtom = atom(null, (get, set) => {
  const lastAppliedFilters = get(lastAppliedFiltersAtom);
  if (lastAppliedFilters === null) return;

  Object.entries(lastAppliedFilters).forEach(([key, value]) => {
    const typedKey = key as ConfigFilterKey;
    const atomValueToSet = filterAtomsToHydrateCallback[typedKey](value);
    set(filterAtomsToHydrate()[typedKey], atomValueToSet);
  });
});
