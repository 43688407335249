import { iqTheme } from '@statsbomb/kitbag-themes';
import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

// Set default theme depending on the colour scheme set on the user's OS
const userDefaultColourScheme =
  window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';

export const themeAtom = atomWithStorage<keyof typeof iqTheme>('theme', userDefaultColourScheme);
export const isDarkModeAtom = atom(get => get(themeAtom) === 'dark');
