import { EventDescription } from '@/types/event';
import { formatGameTimestamp } from '@/utils/games';
import { snakeToCamel } from '@/utils/queries';
import { Typography } from '@statsbomb/kitbag-components';
import { useTranslation } from 'react-i18next';

export const EventMapTooltip = ({ markerDescription }: { markerDescription: EventDescription }) => {
  const { t } = useTranslation('events');

  const eventType = t(`fields.event.type.${snakeToCamel(markerDescription.type)}`);
  const timestamp = formatGameTimestamp(markerDescription.minute, markerDescription.second);

  return (
    <div className="max-w-[350px]">
      <Typography colour="inverse.main" variant="headingSmall" as="h3">
        {markerDescription.playerDisplayName} - {eventType}
      </Typography>
      <Typography colour="inverse.main" variant="bodyMedium" as="div">
        {timestamp} - {markerDescription.gameName}
      </Typography>
      <Typography colour="inverse.main" variant="bodyMedium" as="div">
        {markerDescription.competitionName} - {markerDescription.gameDate}
      </Typography>
    </div>
  );
};
