import { useAtom, useAtomValue } from 'jotai';
import {
  hasPlayerAggsCompAndSeasonFilterParamsAtom,
  playerAggsBaseFiltersAtom,
  playerAggsFilterParamsAtom,
} from '@/atoms/queries/player/playerAggData';
import { useDeepEffect } from './useDeepEffect';

/* this is used to set the filters in the playerAggsBaseFiltersAtom from the 
default filters set in the playerAggsFilterParamsAtom. As we need an atom
which stores our filters only on an apply button being pressed
and doesn't automatically update when filters are changed */
export const useInitialisePlayerAggsFilters = () => {
  const playerAggsFilterParams = useAtomValue(playerAggsFilterParamsAtom);
  const [playerAggsBaseFilters, setPlayerAggsBaseFilters] = useAtom(playerAggsBaseFiltersAtom);
  const hasLoadedDefaultFilterParams = useAtomValue(hasPlayerAggsCompAndSeasonFilterParamsAtom);

  useDeepEffect(() => {
    if (hasLoadedDefaultFilterParams && playerAggsBaseFilters === null) {
      setPlayerAggsBaseFilters(playerAggsFilterParams);
    }
  }, [hasLoadedDefaultFilterParams, playerAggsBaseFilters]);
};
