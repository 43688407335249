import { PrimitiveAtom, useAtom } from 'jotai';
import { Slider } from '@statsbomb/kitbag-components';
import { Label, SliderOnChange } from '@statsbomb/kitbag-components/types/components/Inputs/Slider/types';
import { sliderFormattedLabel } from '@/utils/sliderFormattedValue';

export const SliderMultiple = ({
  title,
  minValue,
  maxValue,
  minAtom,
  maxAtom,
  canGoBelowMinValue = false,
  canGoAboveMaxValue = false,
  step,
  labels,
}: {
  title: string;
  minValue: number;
  maxValue: number;
  minAtom: PrimitiveAtom<number>;
  maxAtom: PrimitiveAtom<number>;
  canGoBelowMinValue?: boolean;
  canGoAboveMaxValue?: boolean;
  step?: number;
  labels?: Label[] | undefined;
}) => {
  const [minSelected, setMinSelected] = useAtom(minAtom);
  const [maxSelected, setMaxSelected] = useAtom(maxAtom);
  const selectedValues: [number, number] = [minSelected, maxSelected];
  const formattedLabel = sliderFormattedLabel(
    minValue,
    maxValue,
    selectedValues,
    canGoBelowMinValue,
    canGoAboveMaxValue,
  );

  const onChange: SliderOnChange = (_, range) => {
    if (!Array.isArray(range)) return;
    const [min, max] = range;

    setMinSelected(min);
    setMaxSelected(max);
  };

  return (
    <Slider
      id={title.toLowerCase()}
      label={title}
      labels={labels}
      max={maxValue}
      min={minValue}
      onChange={onChange}
      value={selectedValues}
      valueLabel={formattedLabel}
      step={step}
    />
  );
};
