import { filterAtomDefaults } from '@/consts/defaults/filterAtomDefaults';
import { ConfigEventFilterSet, ConfigFilterSet, ConfigGameFilterSet, ConfigPlayerFilterSet } from '@/types/userConfigs';
import { convertOptionsToValues } from '@/utils/array';
import { atom } from 'jotai';
import { Getter } from 'jotai/vanilla';
import { selectedBodyPartsAtom, selectedKeeperActionsAtom, selectedPassHeightAtom } from '../eventData';
import { metricKeyAtom } from '../metric';
import { maxAgeSelectedAtom, minAgeSelectedAtom } from './dataLocker/ageRange';
import { maxHeightSelectedAtom, minHeightSelectedAtom } from './dataLocker/heightRange';
import { maxMinutesSelectedAtom, minMinutesSelectedAtom } from './dataLocker/minMinutes';
import { selectedPreferredFootAtom } from './dataLocker/preferredFoot';
import { selectedEventNamesAtom, selectedOutcomesAtom } from './events';
import { selectedGameTypeAtom } from './gameMetrics';
import {
  deselectedGamesAtom,
  selectedEndDateAtom,
  selectedGameRangeAtom,
  selectedPeriodRangeAtom,
  selectedStartDateAtom,
} from './highLevel';
import { selectedCompetitionsAtom } from './highLevel/competitions';
import { selectedPlayerIdsAtom } from './highLevel/players';
import { selectedPositionsAtom } from './highLevel/positions';
import { seasonIdsToFilterByAtom } from './highLevel/seasons';
import { selectedTeamIdsAtom } from './highLevel/teams';
import { selectedPitchFilterEndZonesAtom, selectedPitchFilterStartZonesAtom } from './pitchFilter';

const gameFilterValuesToPersist = (get: Getter): ConfigGameFilterSet => {
  const selectedGameRange = get(selectedGameRangeAtom).key;
  return {
    periodRange: {
      value: selectedGameRange === 'period' ? get(selectedPeriodRangeAtom).value : filterAtomDefaults.periodRange.value,
    },
    gameRange: get(selectedGameRangeAtom).key,
    startDate: selectedGameRange === 'dateRange' ? get(selectedStartDateAtom) : filterAtomDefaults.startDate,
    endDate: selectedGameRange === 'dateRange' ? get(selectedEndDateAtom) : filterAtomDefaults.endDate,
    gameType: get(selectedGameTypeAtom).value,
    deselectedGameIds: convertOptionsToValues(get(deselectedGamesAtom)),
  };
};

const eventFilterValuesToPersist = (get: Getter): ConfigEventFilterSet => ({
  eventNames: get(selectedEventNamesAtom),
  outcomes: get(selectedOutcomesAtom),
  bodyParts: get(selectedBodyPartsAtom),
  passHeights: get(selectedPassHeightAtom),
  keeperActions: get(selectedKeeperActionsAtom),
  pitchStartZones: get(selectedPitchFilterStartZonesAtom),
  pitchEndZones: get(selectedPitchFilterEndZonesAtom),
});

const playerFilterValuesToPersist = (get: Getter): ConfigPlayerFilterSet => ({
  minAge: get(minAgeSelectedAtom),
  maxAge: get(maxAgeSelectedAtom),
  minHeight: get(minHeightSelectedAtom),
  maxHeight: get(maxHeightSelectedAtom),
  minMinutes: get(minMinutesSelectedAtom),
  maxMinutes: get(maxMinutesSelectedAtom),
  preferredFoot: get(selectedPreferredFootAtom),
});

export const filtersToPersistAtom = atom(get => {
  const persistValues: ConfigFilterSet = {
    // Deliberately not using the competitionIdsToFilterByAtom here as when no competitions are selected,
    // we pass up all the available competition options in the query to ensure we only get data for the gender
    // we want. But we don't want to persist all those competition ids, so using selectedCompetitionsAtom means
    // we persist the selection that is shown on the page. Filtering by gender still works on the report as we also
    // persist the gender which is then used to populate available competition options for the widget.
    competitionIds: get(selectedCompetitionsAtom).map(({ value }) => value),
    seasonIds: get(seasonIdsToFilterByAtom),
    playerIds: get(selectedPlayerIdsAtom),
    teamIds: get(selectedTeamIdsAtom),

    positions: get(selectedPositionsAtom),

    metric: get(metricKeyAtom),

    ...gameFilterValuesToPersist(get),
    ...eventFilterValuesToPersist(get),
    ...playerFilterValuesToPersist(get),
  };
  return persistValues;
});

export const filterChangesToApplyAtom = atom(get => {
  const persistValues: ConfigFilterSet = {
    ...get(filtersToPersistAtom),
    /* these are to override the startDate and endDate values from
    gameFilterValuesToPersist as we don't want the selectedGameRange === 'dateRange' 
    logic where this atom is used */
    startDate: get(selectedStartDateAtom),
    endDate: get(selectedEndDateAtom),
  };
  return persistValues;
});

export const lastAppliedFiltersAtom = atom<ConfigFilterSet | null>(null);

const filterStateHistoryBaseAtom = atom<{ pathname: string; filtersToPersist: ConfigFilterSet }[]>([]);

export const filterStateHistoryAtom = atom(
  get => get(filterStateHistoryBaseAtom),
  (get, set, pathname: string) => {
    const filterStateHistory = get(filterStateHistoryBaseAtom);
    const filtersToPersist = get(filtersToPersistAtom);

    const newHistoryItem = { pathname, filtersToPersist };

    /* this and the `isPathNameDifferent` checks are both to add a new item
    we are doing this check first as if we don't the lastHistory item line
    will fail due to not having any items in filterStateHistoryHistory */
    if (filterStateHistory.length === 0) {
      set(filterStateHistoryBaseAtom, [...filterStateHistory, newHistoryItem]);
      return;
    }

    const lastHistoryItem = filterStateHistory[filterStateHistory.length - 1];
    const isPathNameDifferent = lastHistoryItem.pathname !== pathname;

    if (isPathNameDifferent) {
      set(filterStateHistoryBaseAtom, [...filterStateHistory, newHistoryItem]);
    } else {
      const newHistory = filterStateHistory.with(filterStateHistory.length - 1, newHistoryItem);
      set(filterStateHistoryBaseAtom, newHistory);
    }
  },
);

export const clearLastFilterStateHistoryAtom = atom(null, (get, set) => {
  const currentHistory = get(filterStateHistoryAtom);
  set(filterStateHistoryBaseAtom, currentHistory.slice(0, currentHistory.length - 1));
});
