import { round } from 'lodash';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { Slider } from '@statsbomb/kitbag-components';
import { getTranslationColumns } from '@/utils/translations';
import { snakeToCamel } from '@/utils/queries';
import { selectedMetricDistributionsAtom, selectedMetricsAtom } from '@/atoms/dataLocker/metrics';
import { SliderOnChange } from '@statsbomb/kitbag-components/types/components/Inputs/Slider/types';
import { useMetricDistributionSliderLabel } from '@/hooks/useMetricDistributionSliderLabel';

export const MetricDistributionSlider = ({
  metric,
  minValue,
  maxValue,
  step,
  precision,
  reverseScale,
}: {
  metric: string;
  minValue: number;
  maxValue: number;
  step: number;
  precision: number;
  reverseScale?: boolean;
}) => {
  const { t } = useTranslation('metrics');
  const { translationKey } = getTranslationColumns(snakeToCamel(metric));
  const [playerDataLockerSelectedMetrics, setPlayerDataLockerSelectedMetrics] = useAtom(selectedMetricsAtom);
  const [selectedMetricDistributions, setSelectedMetricDistributions] = useAtom(selectedMetricDistributionsAtom);

  const range = maxValue - minValue;
  // adding 8% extra to the range to allow for the slider to be dragged over the min/max values
  const extra = round((range / 100) * 8, precision);
  // makes going over the min/max values more intentional
  const padding = extra / 2;

  const sliderMin = reverseScale ? 0 : minValue;
  const sliderMax = reverseScale ? range : maxValue;
  const minUnsetValue = round(sliderMin - extra, precision);
  const maxUnsetValue = round(sliderMax + extra, precision);

  const savedMin = selectedMetricDistributions[metric]?.min;
  const savedMax = selectedMetricDistributions[metric]?.max;

  const savedMinValue = savedMin ?? minValue - extra;
  const savedMaxValue = savedMax ?? maxValue + extra;

  // convert saved values to reverse slider scale values
  const offset = maxValue - savedMaxValue;
  const savedRange = savedMaxValue - savedMinValue;
  const sliderSelectedMin = reverseScale ? sliderMin + offset : savedMinValue;
  const sliderSelectedMax = reverseScale ? sliderSelectedMin + savedRange : savedMaxValue;

  // converts a slider value to the value we actually want to save
  const toSavedValue = (value: number) => round(reverseScale ? maxValue - value : value, precision);

  const getMinToSave = (newMin: number) => {
    const newSavedMin = toSavedValue(newMin);
    // handle dragged over the min value but NOT enough to select "open end"
    if (newSavedMin < minValue && newSavedMin > minValue - padding) return minValue;
    // handle dragged over the min value enough to select "open end"
    if (newSavedMin <= minValue - padding) return null;
    // prevent the min handle from going over the max value
    if (newSavedMin > maxValue) return maxValue;
    return newSavedMin;
  };

  const getMaxToSave = (newMax: number) => {
    const newSavedMax = toSavedValue(newMax);
    if (newSavedMax > maxValue && newSavedMax < maxValue + padding) return maxValue;
    if (newSavedMax >= maxValue + padding) return null;
    if (newSavedMax < minValue) return minValue;
    return newSavedMax;
  };

  const onChange: SliderOnChange = (_, range) => {
    if (!Array.isArray(range)) return;
    const [min, max] = range;
    const minToSave = getMinToSave(reverseScale ? max : min);
    const maxToSave = getMaxToSave(reverseScale ? min : max);

    const newMetricDistribution = {
      ...selectedMetricDistributions,
      [metric]: {
        min: minToSave,
        max: maxToSave,
      },
    };

    setSelectedMetricDistributions(newMetricDistribution);
  };

  const onDelete = () =>
    setPlayerDataLockerSelectedMetrics(playerDataLockerSelectedMetrics.filter(key => key !== metric));

  const title = t(translationKey);

  const minDisplayValue = reverseScale ? savedMaxValue : savedMinValue;
  const maxDisplayValue = reverseScale ? savedMinValue : savedMaxValue;
  const formattedLabel = useMetricDistributionSliderLabel(
    minValue,
    maxValue,
    minDisplayValue,
    maxDisplayValue,
    reverseScale,
  );

  const minLabel = reverseScale ? maxValue : minValue;
  const maxLabel = reverseScale ? minValue : maxValue;
  const labels = [
    { label: '<', position: minUnsetValue },
    { label: minLabel.toString(), position: sliderMin },
    { label: maxLabel.toString(), position: sliderMax },
    { label: '>', position: maxUnsetValue },
  ];

  return (
    <Slider
      id={metric}
      label={title}
      labels={labels}
      min={minUnsetValue}
      max={maxUnsetValue}
      step={step}
      value={[sliderSelectedMin, sliderSelectedMax]}
      valueLabel={formattedLabel}
      onChange={onChange}
      onDelete={onDelete}
      // @ts-ignore - TODO (EPT-6001: Fix slider mock component)
      test
    />
  );
};
