import { atom } from 'jotai';
import { groupTableColumns } from '@/utils/table';
import { objSnakeToCamel } from '@/utils/queries';
import { MetricDistributionDefinition } from '@/types/metric';
import { getPlayerMetricDefinitions } from '@/utils/metrics';
import { rawTableColumnsAtom } from '../queries/tableColumns';
import { playerMetricDistributionsAtom, playerMetricsAtom } from '../queries/player/playerRadar';

export const selectedMetricsAtom = atom<string[]>([]);

export const selectedMetricDistributionsAtom = atom<Record<string, { min: number | null; max: number | null }>>({});

export const playerMetricGroupsAtom = atom(async get => {
  const { data } = await get(rawTableColumnsAtom);

  const playerMetricTableColumns = data
    .filter(metric => metric.category_branch.key === 'player_metric')
    .map(objSnakeToCamel);

  const playerMetricGroups = groupTableColumns(playerMetricTableColumns);

  return playerMetricGroups;
});

export const playerMetricDistributionDefinitionsAtom = atom(async get => {
  const [playerMetrics, playerMetricDistributions] = await Promise.all([
    get(playerMetricsAtom),
    get(playerMetricDistributionsAtom),
  ]);

  const playerMetricDistributionDefinitions: MetricDistributionDefinition[] = getPlayerMetricDefinitions(
    playerMetrics,
    playerMetricDistributions,
  );

  return playerMetricDistributionDefinitions;
});
