import { useTranslation } from 'react-i18next';
import { MAX_HEIGHT, MIN_HEIGHT } from '@/consts/filters/heightRange';
import { maxHeightSelectedAtom, minHeightSelectedAtom } from '@/atoms/filters/dataLocker/heightRange';
import { SliderMultiple } from '../sliders/SliderMultiple';

export const HeightRangeFilter = () => {
  const { t } = useTranslation('filters');

  return (
    <SliderMultiple
      title={t('height')}
      minValue={MIN_HEIGHT}
      maxValue={MAX_HEIGHT}
      minAtom={minHeightSelectedAtom}
      maxAtom={maxHeightSelectedAtom}
      canGoBelowMinValue
      canGoAboveMaxValue
      labels={[
        { label: MIN_HEIGHT.toString(), position: MIN_HEIGHT },
        { label: MAX_HEIGHT.toString(), position: MAX_HEIGHT },
      ]}
    />
  );
};
