import { NestedObject } from '@/types/object';
import { TFunction } from 'i18next';
import { ColumnGroup, DataTableColumn } from '@/types/table';
import { LINK_SORTING_MAP } from '@/consts/ordering';
import { snakeToCamel } from './queries';
import { getTranslationColumns } from './translations';

export const translateValue = (key: string, value: NestedObject[string], t?: TFunction) => {
  if (!value) return '';
  if (!t || typeof value !== 'string') return value;

  return t(`fields.${key}.${snakeToCamel(value.toLowerCase())}`);
};

export const translateHyphenatedValue = (key: string, value: NestedObject[string], t?: TFunction) => {
  if (!value) return '';
  if (!t || typeof value !== 'string') return value;
  const splitVals = value.split(' - ');
  const translatedVals = splitVals
    .map(valueElement => valueElement.replaceAll(' ', '_'))
    .map(valueElement => translateValue(key, valueElement, t));
  return translatedVals.join(' - ');
};

export const getHighlightedColumnIndex = (sortedColumnIndex: number, isVideoColumnVisible: boolean) => {
  if (sortedColumnIndex === -1) {
    return undefined;
  }
  // If video column is not visible, we need to + 1 to convert 0 based index to 1 based index
  // If video column is visible, we need to + 2 to convert 0 based index to 1 based index and account for video column
  return sortedColumnIndex + (isVideoColumnVisible ? 2 : 1);
};

// aligns table cell values depending on format rule
// text based values align to the left and numeric values align to the right
export const alignTableCell = (formatRule: string | undefined) =>
  !formatRule || formatRule.includes('string') || formatRule === 'link' || formatRule === 'date' ? 'left' : 'right';

export const isLowerBetter = (formatRule = '') => ['string', 'string-translate', 'link'].includes(formatRule);

export const getValidColumnKey = (tableColumnKey: string) => LINK_SORTING_MAP[tableColumnKey] || tableColumnKey;

export const getFormatRule = (tableColumnKey: string, tableColumns?: DataTableColumn[]) =>
  tableColumns?.find(({ tableColumn }) => getValidColumnKey(tableColumn.key) === getValidColumnKey(tableColumnKey))
    ?.tableColumn.formatRule;

/**
 * Group and format columns. Groups with the same name are collapsed into a single group
 * */
export const groupTableColumns = (tableColumns: DataTableColumn[]) =>
  Object.values(
    tableColumns.reduce(
      (acc, { tableColumn: { key: tableColumnKey }, categoryLeaf: { name: groupName } }) => {
        const existingOptions = acc[groupName]?.options || [];
        const newOption = { value: tableColumnKey, label: getTranslationColumns(snakeToCamel(tableColumnKey)) };

        return {
          ...acc,
          [groupName]: {
            header: { translationKey: snakeToCamel(groupName), translationNs: 'columnGroups' },
            options: [...existingOptions, newOption],
          },
        };
      },
      {} as Record<string, ColumnGroup>,
    ),
  );
