export const sliderFormattedLabel = (
  minValue: number,
  maxValue: number,
  selectedValues: [number, number],
  canGoBelowMinValue = false,
  canGoAboveMaxValue = false,
): string => {
  const [minSelected, maxSelected] = selectedValues;

  if (minSelected === maxSelected) {
    if (canGoBelowMinValue && minValue === minSelected) return `<=${minSelected}`;
    if (canGoAboveMaxValue && maxValue === maxSelected) return `${maxSelected}+`;
    return `${minSelected}`;
  }

  const minLabel = canGoBelowMinValue && minValue === minSelected ? `<${minValue}` : `${minSelected}`;
  const maxLabel = canGoAboveMaxValue && maxValue === maxSelected ? `${maxValue}+` : `${maxSelected}`;

  return `${minLabel} - ${maxLabel}`;
};
