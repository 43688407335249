import { selectedSeasonIdsAtom, selectedSeasonsAtom } from '@/atoms/filters/highLevel/seasons';
import { SelectorProps } from '@/types/filters';
import { useAtomValue, useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { convertOptionsToValues } from '@/utils/array';
import { FilterDropdown } from '../../dropdowns/FilterDropdown';

export const SeasonSelectorDropdown = ({ options = [], isLoading, isMulti }: SelectorProps) => {
  const { t } = useTranslation(['filters', 'entity']);
  const selectedSeasons = useAtomValue(selectedSeasonsAtom);
  const setSelectedSeasonIds = useSetAtom(selectedSeasonIdsAtom);

  return (
    <FilterDropdown
      id="season-multi-select"
      label={t('season.one', { ns: 'entity' })}
      selectedOption={selectedSeasons}
      options={options}
      onChange={options => setSelectedSeasonIds(convertOptionsToValues(options))}
      placeholder={t('dropdownPlaceholder')}
      isLoading={isLoading}
      isMulti={isMulti}
    />
  );
};
