import { Accordion } from '@/components/accordion/Accordion';
import { useTranslation } from 'react-i18next';
import { GameRangeFilterGroup } from '@/components/filters/GameRangeFilterGroup';
import { CompetitionSelector } from '@/components/filters/CompetitionSelector';
import { GameSelector } from '@/components/filters/GameSelector';
import { playerGamesBySeasonAtom, playerSelectedGamesAtom } from '@/atoms/filters/player/playerGames';
import { GameTypeFilter } from '@/components/filters/GameTypeFilter';
import { TeamSelector } from '@/components/filters/TeamSelector';
import { ResetAllFiltersButton } from '@/components/button/ResetAllFiltersButton';
import { SeasonSelector } from '@/components/filters/Season/SeasonSelector';
import { UserFilters } from '@/components/filters/UserFilters/UserFilters';

export const PlayerGamesFilters = () => {
  const { t } = useTranslation('filters');

  return (
    <div className="flex flex-col">
      <UserFilters />
      <Accordion title={t('general')} isDefaultExpanded>
        <ResetAllFiltersButton />
        <CompetitionSelector isMulti />
        <SeasonSelector />
        <TeamSelector />
      </Accordion>
      <Accordion title={t('games')} isDefaultExpanded>
        <GameRangeFilterGroup />
        <GameTypeFilter />
        <GameSelector gamesGroupedBySeasonAtom={playerGamesBySeasonAtom} selectedGamesAtom={playerSelectedGamesAtom} />
      </Accordion>
    </div>
  );
};
