import { ColumnGroup } from '@/types/table';
import { sortObjectArray } from '@/utils/array';
import { useTranslation } from 'react-i18next';

export const useGroupedOptions = (groupedOptions: ColumnGroup[]) => {
  const { t, i18n } = useTranslation();

  const translatedGroupedOptions = groupedOptions.map(({ header, options }) => ({
    header: t(header.translationKey, { ns: header.translationNs }),
    options: sortObjectArray(
      'label',
      options.map(({ value, label }) => ({
        value,
        label: t(label.translationKey, { ns: label.translationNs }),
      })),
      i18n.language,
    ),
  }));

  const sortedGroupedOptions = sortObjectArray('header', translatedGroupedOptions, i18n.language);

  return sortedGroupedOptions;
};
