import { unwrappedSelectedPlayerNameAtom } from '@/atoms/player/info';
import { playerMetricDataAtom } from '@/atoms/player/metric';
import { playerEventMetricFiltersAtom, playerEventsForMetricCountAtom } from '@/atoms/queries/player/playerEventData';
import { EventDataControls } from '@/components/controls/EventsDataControls';
import { PageTitle } from '@/components/head/PageTitle';
import { PlayerLayoutWithFilters } from '@/components/layout/player/PlayerLayoutWithFilters';
import { MetricKeyDropdown } from '@/components/metric/MetricKeyDropdown';
import { PlayerVisEventsTable } from '@/components/player/vis/PlayerVisEventsTable';
import { VisDisplaySection } from '@/components/section/VisDisplaySection';
import { EventCountInfoBar } from '@/components/visualisations/EventCountInfoBar';
import { PitchVisualisation } from '@/components/visualisations/PitchVisualisation/PitchVisualisation';
import { playerPitchVisComponents } from '@/consts/visualisationComponents';
import { useEntityPageTitle } from '@/hooks/useEntityPageTitle';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import { metricKeyAtom } from '@/atoms/metric';
import { playerIdAtom } from '@/atoms/player/player';
import { playerMetricEventsUrl } from '@/query/url';
import { useTableColumns } from '@/hooks/useTableColumns';
import { ScopeProvider } from 'jotai-scope';
import { tableColumnEntitiesAtom } from '@/atoms/queries/tableColumns';
import { useCsvDownload } from '@/hooks/exporting/useCsvDownload';
import { VisSectionContainer } from '@/components/layout/VisSectionContainer';
import { EVENTS_TABLE_COLUMN_ENTITIES } from '@/consts/dataLocker/events';
import { unwrappedEventsDataDefaultColumnsAtom } from '@/atoms/dataLocker/events/table';
import { ScopedTableWithDefaultColumns } from '@/components/tables/ScopedTableWithDefaultColumns';
import { TableId } from '@/types/table';

const CsvDownload = ({ pageTitle }: { pageTitle: string }) => {
  const metricKey = useAtomValue(metricKeyAtom);
  const playerId = useAtomValue(playerIdAtom);
  const playerEventMetricFilters = useAtomValue(playerEventMetricFiltersAtom);
  useCsvDownload(pageTitle, playerMetricEventsUrl(playerId, metricKey, playerEventMetricFilters));
  return null;
};

const VisDisplaySectionWithControls = () => {
  useTableColumns(EVENTS_TABLE_COLUMN_ENTITIES);

  const metricKeyDropdownComponent = <MetricKeyDropdown metricDataAtom={playerMetricDataAtom} />;

  return (
    <VisSectionContainer>
      <EventDataControls metricKeyDropdownComponent={metricKeyDropdownComponent} />
      <VisDisplaySection
        EventsTableComponent={PlayerVisEventsTable}
        visualisationComponent={
          <PitchVisualisation
            visualisations={playerPitchVisComponents}
            headerComponent={metricKeyDropdownComponent}
            footerComponent={<EventCountInfoBar eventCountAtom={playerEventsForMetricCountAtom} />}
          />
        }
      />
    </VisSectionContainer>
  );
};

export const PlayerVisPage = () => {
  const { t } = useTranslation('navigation');
  const pageTitle = useEntityPageTitle(t('secondary.pitchVis'), useAtomValue(unwrappedSelectedPlayerNameAtom));

  return (
    <ScopeProvider atoms={[tableColumnEntitiesAtom]}>
      <PageTitle>{pageTitle}</PageTitle>
      <PlayerLayoutWithFilters>
        <ScopedTableWithDefaultColumns
          defaultColumnsAtom={unwrappedEventsDataDefaultColumnsAtom}
          tableId={TableId.PLAYER_EVENTS}
        >
          <CsvDownload pageTitle={pageTitle} />
          <VisDisplaySectionWithControls />
        </ScopedTableWithDefaultColumns>
      </PlayerLayoutWithFilters>
    </ScopeProvider>
  );
};
