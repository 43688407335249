import {
  playerAggsBaseFiltersAtom,
  playerAggsFilterParamsAtom,
  playerAggsShowApplyButtonAtom,
} from '@/atoms/queries/player/playerAggData';
import { useApplyFiltersButton } from '@/hooks/useApplyFiltersButton';
import { Button } from '@statsbomb/kitbag-components';
import { useAtomValue, useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

export const PlayerAggsApplyFiltersButton = () => {
  const { t } = useTranslation('general');
  const { onCancel, onApply } = useApplyFiltersButton();
  const showApplyFiltersButton = useAtomValue(playerAggsShowApplyButtonAtom);
  const playerAggsFilterParams = useAtomValue(playerAggsFilterParamsAtom);
  const setPlayerAggsFilters = useSetAtom(playerAggsBaseFiltersAtom);

  return (
    showApplyFiltersButton && (
      <div className="flex justify-end gap-2 sticky bottom-0 p-4 mt-auto bg-canvas-tertiary-main border-t border-canvas-primary-main z-[1]">
        <Button onClick={onCancel} variant="ghost">
          {t('cancel')}
        </Button>
        <Button
          onClick={() => {
            onApply();
            setPlayerAggsFilters(playerAggsFilterParams);
          }}
        >
          {t('apply')}
        </Button>
      </div>
    )
  );
};
