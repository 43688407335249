import { ReactNode, useEffect } from 'react';
import { Provider as JotaiProvider, useSetAtom, useAtomValue } from 'jotai';
import { ThemeProvider, iqTheme } from '@statsbomb/kitbag-themes';
import { AppState, AuthProtectedComponent, AuthProvider, useKitbagAuth } from '@statsbomb/kitbag-auth';
import { useNavigate } from 'react-router-dom';
import { themeAtom } from '@/atoms/theme';
import env from '@/env';
import { useTracking } from '@/hooks/useTracking';
import { QueryClientProvider } from '@tanstack/react-query';
import { getFetchClient, getQueryClient } from '@/query/client';
import { useErrorMonitoring } from '@/hooks/useErrorMonitoring';
import { fetchClientAtom } from '@/atoms/queries/client';
import { useAtomsDevtools } from 'jotai-devtools';
import { useHydrateAtoms } from 'jotai/utils';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { queryClientAtom } from 'jotai-tanstack-query';
import { VideoKeyBoardShortcutProvider, VideoProvider as VideoLibraryProvider } from '@statsbomb/video-client-js';
import { useVideoPlayerTranslations } from '@/hooks/useVideoPlayerTranslations';

const FetchProvider = ({ children }: { children: ReactNode }) => {
  const { getAccessTokenSilently } = useKitbagAuth();
  const setFetchClient = useSetAtom(fetchClientAtom);

  useEffect(() => {
    setFetchClient({ fetch: getFetchClient(getAccessTokenSilently()) });
  }, [getAccessTokenSilently, setFetchClient]);

  return children;
};

const TrackingProvider = ({ children }: { children: ReactNode }) => {
  useTracking();

  return children;
};

const queryClient = getQueryClient();

const InitJotaiQueryClient = ({ children }: { children: React.ReactNode }) => {
  // Hydration for Jotai does not care if it's server to browser/client, it is for initialising atoms.
  // https://jotai.org/docs/extensions/query#referencing-the-same-instance-of-query-client-in-your-project
  useHydrateAtoms([[queryClientAtom, queryClient]]);
  return children;
};

const AuthedClientProvider = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();

  const onRedirectCallback = (appState?: AppState) => {
    navigate((appState && appState.returnTo) || window.location.pathname);
  };

  return (
    <AuthProvider
      providerOptions={{
        authorizationParams: {
          redirect_uri: window.location.origin,
        },
        clientId: env.VITE_AUTH0_CLIENT_ID,
        domain: env.VITE_AUTH0_DOMAIN,
        onRedirectCallback,
      }}
    >
      <TrackingProvider>
        <JotaiProvider>
          <InitJotaiQueryClient>
            <FetchProvider>{children}</FetchProvider>
          </InitJotaiQueryClient>
        </JotaiProvider>
      </TrackingProvider>
    </AuthProvider>
  );
};

// We needed to separate the theme provider so we could pass in the Jotai state
// Jotai state must be defined below the provider or it doesn't work
export const KitbagThemeProvider = ({ children }: { children: ReactNode }) => {
  const theme = useAtomValue(themeAtom);
  return <ThemeProvider theme={iqTheme[theme]}>{children}</ThemeProvider>;
};

export const VideoProvider = ({ children }: { children: ReactNode }) => {
  const translationOverrides = useVideoPlayerTranslations();

  return (
    <AuthProtectedComponent>
      <VideoLibraryProvider translations={translationOverrides}>
        <VideoKeyBoardShortcutProvider>{children}</VideoKeyBoardShortcutProvider>
      </VideoLibraryProvider>
    </AuthProtectedComponent>
  );
};

export const Providers = ({ children }: { children: ReactNode }) => {
  useErrorMonitoring();
  useAtomsDevtools('parachute-ui');
  return (
    <QueryClientProvider client={queryClient}>
      <AuthedClientProvider>
        <KitbagThemeProvider>{children}</KitbagThemeProvider>
      </AuthedClientProvider>
      {env.VITE_ENABLE_TANSTACK_DEVTOOLS === '1' && <ReactQueryDevtools position="bottom" />}
    </QueryClientProvider>
  );
};
