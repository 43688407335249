import { useTranslation } from 'react-i18next';
import { useAtomValue } from 'jotai';
import { csvDownloadDataAtom } from '@/atoms/exporting';
import { pageAndOrderParamsAtom } from '@/atoms/general';
import { visibleTableColumnsAtom } from '@/atoms/table';
import { tableColumnsToUrlParam } from '@/components/export/Export.helper';
import { convertFilterParamsToString } from '@/utils/api';

export const useCsvUrl = () => {
  const {
    i18n: { language },
  } = useTranslation('csv');
  const { url } = useAtomValue(csvDownloadDataAtom);
  const tableColumns = useAtomValue(visibleTableColumnsAtom);
  const pageAndOrderParams = useAtomValue(pageAndOrderParamsAtom);
  const selectedTableColumns = tableColumnsToUrlParam(tableColumns);

  const csvQueryParams = {
    lang: language,
    order_by: pageAndOrderParams.order_by,
    columns: selectedTableColumns || [],
  };

  const csvParamsString = convertFilterParamsToString(csvQueryParams);
  const formattedParams = url.includes('?') ? csvParamsString.replace('?', '&') : csvParamsString;

  const formattedUrl = `${url}${formattedParams}`;

  return formattedUrl;
};
