import { ReactNode } from 'react';
import { CompetitionSelector } from '@/components/filters/CompetitionSelector';
import { Accordion } from '@/components/accordion/Accordion';
import { useTranslation } from 'react-i18next';
import { TeamSelectorWithAllOption } from '@/components/filters/Game/TeamSelectorWithAllOption';
import { Button } from '@statsbomb/kitbag-components';
import { GameSelector } from '@/components/filters/Game/GameSelector';
import { SeasonSelector } from '@/components/filters/Season/SeasonSelector';
import { LayoutWithFilters } from '../LayoutWithFilters';

const GameLayoutFilters = () => {
  const { t } = useTranslation('filters');

  return (
    <div className="flex flex-col">
      <Accordion title={t('selectMatch')} isDefaultExpanded>
        <CompetitionSelector isMulti={false} />
        <SeasonSelector persist />
        <TeamSelectorWithAllOption />
        <GameSelector />
        <div>
          <Button variant="primary" size="small" isDisabled title="Not yet implemented">
            Select match
          </Button>
        </div>
      </Accordion>
    </div>
  );
};

export const GameLayoutWithFilters = ({ children }: { children: ReactNode }) => (
  <LayoutWithFilters filters={<GameLayoutFilters />}>{children}</LayoutWithFilters>
);
