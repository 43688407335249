import { initialSentrySetup } from '@/utils/analytics';
import { useKitbagAuth } from '@statsbomb/kitbag-auth';

export const useTracking = () => {
  const { user } = useKitbagAuth();

  if (user?.sub) {
    initialSentrySetup(user.sub, user.email);
  }
};
