import {
  hasRetrievedAllPlayerAggsAtom,
  playerAggsDataAtom,
  rawPlayerAggsCountAtom,
  playerAggsShowApplyButtonAtom,
} from '@/atoms/queries/player/playerAggData';
import { tableColumnsAtom } from '@/atoms/queries/tableColumns';
import { GeneralErrorBoundaryFallback } from '@/components/errorBoundary/ErrorBoundaryFallback';
import { ErrorBoundary } from '@sentry/react';
import { useAtom, useAtomValue } from 'jotai';
import { Suspense } from 'react';
import { unwrappedVisibleTableColumnsAtom } from '@/atoms/table';
import { useTableColumns } from '@/hooks/useTableColumns';
import { AggsTable } from './AggsTable';
import { AggsTableErrorFallback } from './AggsTableErrorFallback';
import { TablePaginationWithoutSuspense } from './TablePagination';

const PlayerAggsTableWithData = () => {
  const [{ data, isLoading, isRefetching }] = useAtom(playerAggsDataAtom);
  const tableColumns = useAtomValue(tableColumnsAtom);
  const visibleColumns = useAtomValue(unwrappedVisibleTableColumnsAtom);
  const hasRetrievedAllPlayerAggs = useAtomValue(hasRetrievedAllPlayerAggsAtom);
  const playerAggsShowApplyButton = useAtomValue(playerAggsShowApplyButtonAtom);
  useTableColumns(['player', 'player_metric']);

  return (
    <AggsTable
      data={data}
      isLoading={isLoading || isRefetching}
      availableColumns={tableColumns}
      visibleColumns={visibleColumns}
      entity="player"
      hasRetrievedAllAggs={hasRetrievedAllPlayerAggs}
      showOverlay={playerAggsShowApplyButton}
    />
  );
};

const PlayerAggsTable = () => (
  <AggsTableErrorFallback>
    <Suspense fallback={<AggsTable isLoading entity="player" />}>
      <PlayerAggsTableWithData />
    </Suspense>
  </AggsTableErrorFallback>
);

export const PlayerAggsTableWithPagination = () => (
  <ErrorBoundary fallback={<GeneralErrorBoundaryFallback />}>
    <PlayerAggsTable />
    <TablePaginationWithoutSuspense dataLengthAtom={rawPlayerAggsCountAtom} />
  </ErrorBoundary>
);
