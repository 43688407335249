import { useTranslation } from 'react-i18next';

export const useMetricDistributionSliderLabel = (
  minValue: number,
  maxValue: number,
  minDisplayValue: number,
  maxDisplayValue: number,
  reverseScale?: boolean,
): string => {
  const { t } = useTranslation('filters');
  const minUnset = reverseScale ? minDisplayValue > maxValue : minDisplayValue < minValue;
  const maxUnset = reverseScale ? maxDisplayValue < minValue : maxDisplayValue > maxValue;

  if (minDisplayValue === maxDisplayValue) return `${minDisplayValue}`;
  if (minUnset && maxUnset) return t('anyValue');

  if (minUnset && !reverseScale) return `≤ ${maxDisplayValue}`;
  if (maxUnset && !reverseScale) return `≥ ${minDisplayValue}`;

  if (minUnset && reverseScale) return `≥ ${maxDisplayValue}`;
  if (maxUnset && reverseScale) return `≤ ${minDisplayValue}`;

  return `${minDisplayValue} - ${maxDisplayValue}`;
};
