interface playerPositionsByPercent {
  percentage: number;
  position: string;
}

export const positionPercentagesTo90sPlayed = (
  playerPositions: playerPositionsByPercent[],
  playerTotalMinutes: number,
) =>
  playerPositions.reduce((acc, { position, percentage }) => {
    const played90s = ((percentage / 100) * playerTotalMinutes) / 90;
    return {
      ...acc,
      [position]: played90s,
    };
  }, {});

export const normalisePositionPercentagesTo100 = (data: playerPositionsByPercent[]): playerPositionsByPercent[] => {
  // Calculate the total of the selected percentages
  const total = data.reduce((sum, item) => sum + item.percentage, 0);

  // Scale each percentage to add up to 100%
  return data.map(item => ({
    ...item,
    percentage: (item.percentage / total) * 100,
  }));
};
