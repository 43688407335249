import { tableColumnsAtom } from '@/atoms/queries/tableColumns';
import { hasRetrievedAllTeamAggsAtom, teamAggsCountAtom, teamAggsDataAtom } from '@/atoms/queries/team/teamAggData';
import { ErrorBoundary } from '@sentry/react';
import { useAtom, useAtomValue } from 'jotai';
import { Suspense } from 'react';
import { unwrappedVisibleTableColumnsAtom } from '@/atoms/table';
import { useTableColumns } from '@/hooks/useTableColumns';
import { GeneralErrorBoundaryFallback } from '../errorBoundary/ErrorBoundaryFallback';
import { AggsTable } from './AggsTable';
import { AggsTableErrorFallback } from './AggsTableErrorFallback';
import { TablePagination } from './TablePagination';

const TeamAggsTableWithData = () => {
  const [{ data, isPending }] = useAtom(teamAggsDataAtom);
  const tableColumns = useAtomValue(tableColumnsAtom);
  const visibleColumns = useAtomValue(unwrappedVisibleTableColumnsAtom);
  const hasRetrievedAllTeamAggs = useAtomValue(hasRetrievedAllTeamAggsAtom);
  useTableColumns(['team', 'team_metric']);

  return (
    <AggsTable
      data={data}
      isLoading={isPending}
      availableColumns={tableColumns}
      visibleColumns={visibleColumns}
      entity="team"
      hasRetrievedAllAggs={hasRetrievedAllTeamAggs}
    />
  );
};

const TeamAggsTable = () => (
  <AggsTableErrorFallback>
    <Suspense fallback={<AggsTable isLoading entity="team" />}>
      <TeamAggsTableWithData />
    </Suspense>
  </AggsTableErrorFallback>
);

export const TeamAggsTableWithPagination = () => (
  <ErrorBoundary fallback={<GeneralErrorBoundaryFallback />}>
    <TeamAggsTable />
    <TablePagination dataLengthAtom={teamAggsCountAtom} />
  </ErrorBoundary>
);
