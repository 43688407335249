import { useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { isMetricFiltersModalOpenAtom } from '@/atoms/dataLocker/player/modal';
import { ButtonIcon, Card, CardBody } from '@statsbomb/kitbag-components';
import { MetricFiltersModal } from '../modal/MetricFiltersModal/MetricFiltersModal';

export const PlayerDataLockerMetricFilters = () => {
  const { t } = useTranslation('filters');
  const setIsMetricFiltersModalOpen = useSetAtom(isMetricFiltersModalOpenAtom);

  return (
    <>
      <div className="h-full flex items-center min-h-36">
        <div className="w-full">
          <Card>
            <CardBody
              statusType="info"
              statusTitle={t('metricInfo.title')}
              statusDescription={t('metricInfo.description')}
            />
            <div className="flex justify-center mt-8">
              <ButtonIcon
                icon="PlusAdd"
                size="small"
                variant="secondary"
                displayText="right"
                onClick={() => setIsMetricFiltersModalOpen(true)}
              >
                {t('addMetricFilter')}
              </ButtonIcon>
            </div>
          </Card>
        </div>
      </div>
      <MetricFiltersModal />
    </>
  );
};
