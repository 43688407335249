import { useTranslation } from 'react-i18next';
import { LayoutWithFilters } from '@/components/layout/LayoutWithFilters';
import { PageTitle } from '@/components/head/PageTitle';
import { ScopeProvider } from 'jotai-scope';
import { tableColumnEntitiesAtom } from '@/atoms/queries/tableColumns';
import { TeamAggsTableWithPagination } from '@/components/tables/TeamAggsTable';
import { TeamAggsFilters } from '@/components/dataLocker/TeamAggsFilters';
import { TableDataControls } from '@/components/controls/TableDataControls';
import { useCsvDownload } from '@/hooks/exporting/useCsvDownload';
import { teamsNormalisedAggregatesUrl } from '@/query/url';
import { useAtomValue } from 'jotai';
import { teamAggsFilterParamsAtom } from '@/atoms/queries/team/teamAggData';
import { ScopedTableWithDefaultColumns } from '@/components/tables/ScopedTableWithDefaultColumns';
import { unwrappedTeamDataDefaultColumnsAtom } from '@/atoms/dataLocker/team/table';
import { TableId } from '@/types/table';
import { EntityAggsContainer } from '../EntityAggsContainer';

const CsvDownload = ({ pageTitle }: { pageTitle: string }) => {
  const teamAggsFilterParams = useAtomValue(teamAggsFilterParamsAtom);
  useCsvDownload(pageTitle, teamsNormalisedAggregatesUrl(teamAggsFilterParams));
  return null;
};

const TableWithControls = () => {
  const { t } = useTranslation(['navigation']);

  return (
    <>
      <TableDataControls heading={t(`viewing.table`, { ns: 'visualisation' })} />
      <TeamAggsTableWithPagination />
    </>
  );
};

const TableWithControlsWrapper = ({ pageTitle }: { pageTitle: string }) => (
  <EntityAggsContainer>
    <CsvDownload pageTitle={pageTitle} />
    <TableWithControls />
  </EntityAggsContainer>
);

export const TeamAggsPage = () => {
  const { t } = useTranslation(['navigation', 'entity']);
  const pageTitle = t('pageTitles.dataLocker', {
    entity: t('team.one', { ns: 'entity' }),
  });

  return (
    <ScopeProvider atoms={[tableColumnEntitiesAtom]}>
      <PageTitle>{pageTitle}</PageTitle>
      {/* NOTE - LayoutWithFilters needs to be inside ScopedTableWithDefaultColumns as it has a dependency on the order by field and order direction. */}
      <ScopedTableWithDefaultColumns
        defaultColumnsAtom={unwrappedTeamDataDefaultColumnsAtom}
        tableId={TableId.TEAM_AGGS}
      >
        <LayoutWithFilters filters={<TeamAggsFilters />}>
          <TableWithControlsWrapper pageTitle={pageTitle} />
        </LayoutWithFilters>
      </ScopedTableWithDefaultColumns>
    </ScopeProvider>
  );
};
