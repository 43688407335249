import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import {
  filtersToPersistAtom,
  filterChangesToApplyAtom,
  lastAppliedFiltersAtom,
} from '@/atoms/filters/persistedFilters';
import { useDeepEffect } from '@/hooks/useDeepEffect';
import { resetFiltersToLastAppliedFiltersAtom } from '@/atoms/filters/resetFilters';
import { hasPlayerAggsCompAndSeasonFilterParamsAtom } from '@/atoms/queries/player/playerAggData';

export const useApplyFiltersButton = () => {
  const filtersToPersist = useAtomValue(filtersToPersistAtom);
  const filterChangesToApply = useAtomValue(filterChangesToApplyAtom);
  const [lastAppliedFilters, setLastAppliedFilters] = useAtom(lastAppliedFiltersAtom);

  const hasLoadedDefaultFilterParams = useAtomValue(hasPlayerAggsCompAndSeasonFilterParamsAtom);
  const resetFiltersToLastAppliedFilters = useSetAtom(resetFiltersToLastAppliedFiltersAtom);

  useDeepEffect(() => {
    /* don't do anything on initial rendering until the default values have been loaded,
      need the lastAppliedFilters === null check here to not keep running the setter
      once the atom is no longer null */
    if (hasLoadedDefaultFilterParams && lastAppliedFilters === null) {
      setLastAppliedFilters(filtersToPersist);
    }
  }, [hasLoadedDefaultFilterParams, lastAppliedFilters]);

  return {
    onCancel: resetFiltersToLastAppliedFilters,
    onApply: () => setLastAppliedFilters(filterChangesToApply),
  };
};
