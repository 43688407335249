import { selectedFilterSetIdAtom } from '@/atoms/filters/userFilters';
import { sectionAtom } from '@/atoms/general';
import { metricKeyAtom } from '@/atoms/metric';
import { playerIdAtom } from '@/atoms/player/player';
import { renderedRadarDataAtom, selectedRadarTemplateIdAtom } from '@/atoms/radar';
import { isMobileFiltersPanelOpenAtom, isSettingsPanelOpenAtom } from '@/atoms/sidebar';
import { teamIdAtom, teamTypeAtom } from '@/atoms/team/team';
import { selectedVideoIdsBaseAtom } from '@/atoms/video';
import { selectedVisEventsAtom } from '@/atoms/vis/selection';
import {
  barChartOrientationAtom,
  isStartPlacementAtom,
  overwrittenVisualisationAtom,
  pitchCropAtom,
  pitchMarkerColouringModeAtom,
  pitchOrientationAtom,
  pitchOrientationReportWidgetAtom,
  pitchOrientationUserPreferenceAtom,
  showArrowsAtom,
  showLegendAtom,
} from '@/atoms/visualisation';
import { itemsPerPageAtom, pageAtom } from '@/atoms/pagination';
import {
  clientOrderByAtom,
  clientOrderDirectionAtom,
  defaultTableColumnsAtom,
  serverOrderByAtom,
  serverOrderDirectionAtom,
  visibleTableColumnsAtom,
  tableIdAtom,
} from '@/atoms/table';
import { csvDownloadDataAtom } from '@/atoms/exporting';
import {
  isEditWidgetTitleModalOpenAtom,
  isWidgetFiltersModalOpenAtom,
  widgetNameToDeleteAtom,
  widgetIdAtom,
} from '@/atoms/report/widget';
import { widgetGenderAtom } from '@/atoms/queries/userConfigs';
import { gameIdAtom } from '@/atoms/game/game';
import { isPlayerGkAtom } from '@/atoms/dataLocker/player/game/table';
import { lastAppliedFiltersAtom } from '@/atoms/filters/persistedFilters';
import { filterAtomsToHydrate } from './atomHydration';

const filterAtoms = [...Object.values(filterAtomsToHydrate()), selectedFilterSetIdAtom];

export const eventAtoms = [selectedVisEventsAtom, selectedVideoIdsBaseAtom, metricKeyAtom];

export const visConfigAtoms = [
  showLegendAtom,
  showArrowsAtom,
  overwrittenVisualisationAtom,
  pitchCropAtom,
  pitchOrientationAtom,
  pitchOrientationReportWidgetAtom,
  pitchOrientationUserPreferenceAtom,
  isStartPlacementAtom,
  pitchMarkerColouringModeAtom,
  barChartOrientationAtom,
  metricKeyAtom,
];

const panelOpenAtoms = [isSettingsPanelOpenAtom, isMobileFiltersPanelOpenAtom];

export const radarAtoms = [selectedRadarTemplateIdAtom, renderedRadarDataAtom];

export const layoutScopeAtoms = [
  ...filterAtoms,
  ...eventAtoms,
  ...visConfigAtoms,
  ...panelOpenAtoms,
  ...radarAtoms,
  playerIdAtom,
  teamIdAtom,
  teamTypeAtom,
  gameIdAtom,
  sectionAtom,
  lastAppliedFiltersAtom,
];

export const tableAtoms = [
  pageAtom,
  itemsPerPageAtom,
  serverOrderByAtom,
  serverOrderDirectionAtom,
  clientOrderByAtom,
  clientOrderDirectionAtom,
  csvDownloadDataAtom,
  defaultTableColumnsAtom,
  visibleTableColumnsAtom,
  tableIdAtom,
  isPlayerGkAtom,
];

export const reportWidgetAtoms = [
  widgetIdAtom,
  widgetGenderAtom,
  widgetNameToDeleteAtom,
  isEditWidgetTitleModalOpenAtom,
  isWidgetFiltersModalOpenAtom,
];
