import { pageAndOrderParamsAtom } from '@/atoms/general';
import { fetchClientAtom } from '@/atoms/queries/client';
import { addLink, flattenAndAddPrefix } from '@/utils/object';
import { objSnakeToCamel } from '@/utils/queries';
import { MultiPlayerAggregate } from '@statsbomb/parachute-types';
import { atom } from 'jotai';
import { atomWithSuspenseQuery } from 'jotai-tanstack-query';

import { ALL_ITEMS } from '@/consts/interface';
import { playersNormalisedAggregatesUrl } from '@/query/url';
import { hasEmptyInFilters } from '@/utils/api';
import { limitArray, sortFetchResultObjectArray } from '@/utils/array';
import { debouncedPlayerAggsFilterParamsAtom } from '../player/playerAggData';

const rawTeamSquadDataAtom = atomWithSuspenseQuery(get => {
  const queryKey = ['teamSquad', get(debouncedPlayerAggsFilterParamsAtom)] as const;
  const queryFn = async ({ queryKey: [, playerAggsFilterParams] }: { queryKey: typeof queryKey }) => {
    if (hasEmptyInFilters(playerAggsFilterParams)) return [];
    const { fetch } = get(fetchClientAtom);
    const data: MultiPlayerAggregate[] = await fetch(
      playersNormalisedAggregatesUrl({
        limit: ALL_ITEMS,
        ...playerAggsFilterParams,
      }),
    );
    return data.map(data => flattenAndAddPrefix(objSnakeToCamel(data), '.', false));
  };
  const enabled = get(debouncedPlayerAggsFilterParamsAtom) !== null;
  return { queryKey, queryFn, enabled };
});

export const teamSquadAtom = atom(async get => {
  const pageAndOrderParams = get(pageAndOrderParamsAtom);
  const teamSquadData = (await get(rawTeamSquadDataAtom)).data;

  const sortedTeamSquadToDisplay = sortFetchResultObjectArray(teamSquadData, pageAndOrderParams);
  const teamSquadPage = limitArray(sortedTeamSquadToDisplay, pageAndOrderParams);

  return teamSquadPage.map(data =>
    addLink(data, data['player.displayName'] as string, data['player.playerId'] as number, 'playerLink'),
  );
});

export const teamSquadCountAtom = atom(async get => (await get(rawTeamSquadDataAtom)).data.length);
