import { SuspenseAtomResult } from '@/types/jotai';
import { EventMarker, EventWithRels } from '@statsbomb/parachute-types';
import { MarkerEvent, ArrowEvent, EventSuccessFail, EventDescription } from '@/types/event';
import { ShotMapEventMarker } from '@/types/visualisation';
import { LinkType } from '@/types/link';
import { addLinks, flattenAndAddPrefix } from '../object';
import { objSnakeToCamel } from '../queries';

export const processEventDataAtom = (rawEventData: SuspenseAtomResult<EventWithRels[]>) =>
  (rawEventData.data || []).map(data => {
    const flattenedEventData = flattenAndAddPrefix(objSnakeToCamel(data), '.', false);

    const linkFields: { label: string; id: number; type: LinkType }[] = [
      { label: data.player.display_name, id: data.player.player_id, type: 'playerLink' },
      { label: data.team.name, id: data.team.team_id, type: 'teamLink' },
    ];

    const recipientLinkFields: { label: string; id: number; type: LinkType }[] = data.recipient_player
      ? [
          {
            label: data.recipient_player.display_name,
            id: data.recipient_player.player_id,
            type: 'recipientPlayerLink',
          },
        ]
      : [];

    return addLinks(flattenedEventData, [...linkFields, ...recipientLinkFields]);
  });

// https://www.notion.so/statsbomb/Colouring-by-Success-in-Parachute-7f894547ec224e8c843538a53dfb8b99?pvs=4#20edc6bc1f9a43d8b1272429b61f926e
export const getSuccessFail = (type: EventMarker['type'], outcome: EventMarker['outcome']): EventSuccessFail => {
  if (type !== 'pass' || outcome === null) {
    return 'neutral';
  }

  return outcome === 'complete' ? 'success' : 'fail';
};

export const convertMarkerDataIntoMarkers = (eventMarkersData: EventMarker[] = []) =>
  eventMarkersData.reduce(
    (acc, event) => {
      const { markerEvents, arrowEvents } = acc;
      if (event.start_x == null || event.start_y == null) return acc;

      const baseEvent = {
        eventId: event.event_id,
        videoEventId: event.video_event_id || undefined,
        startX: event.start_x,
        startY: event.start_y,
        successFail: getSuccessFail(event.type, event.outcome),
        xg: event.xg,
      };

      return event.end_x != null && event.end_y != null
        ? {
            markerEvents,
            arrowEvents: [
              ...arrowEvents,
              {
                ...baseEvent,
                endX: event.end_x,
                endY: event.end_y,
              },
            ],
          }
        : { markerEvents: [...markerEvents, baseEvent], arrowEvents };
    },
    {
      markerEvents: <MarkerEvent[]>[],
      arrowEvents: <ArrowEvent[]>[],
    },
  );

export const convertMarkerDataIntoDescriptions = (eventMarkersData: EventMarker[] = []) =>
  eventMarkersData.map(
    event =>
      ({
        eventId: event.event_id,
        videoEventId: event.video_event_id,
        type: event.type,
        minute: event.minute,
        second: event.second,
        playerDisplayName: event.player_display_name,
        gameName: event.game_name,
        competitionName: event.competition_name,
        seasonName: event.season_name,
        gameDate: event.game_date,
      }) as EventDescription,
  );

export const convertEventDataIntoDescriptions = (eventData: EventWithRels[] = []) =>
  eventData.map(
    ({ player, event, game, season, competition }) =>
      ({
        eventId: event.event_id,
        videoEventId: event.attributes.originating_arqam_eventId || event.attributes.arqam_event_id,
        type: event.type,
        minute: event.attributes.minute,
        second: event.attributes.second,
        playerDisplayName: player.display_name,
        gameName: game.name,
        competitionName: competition.name,
        seasonName: season.name,
        gameDate: game.date,
      }) as EventDescription,
  );

export const convertMarkerDataToShotMapMarkers = (rawMarkers: EventMarker[]) =>
  rawMarkers
    .filter(marker => marker.start_x && marker.start_y)
    .map(
      marker =>
        ({
          type: marker.type,
          eventId: marker.event_id,
          outcome: marker.outcome,
          xg: marker.xg,
          penalty: marker.penalty,
          freeKick: marker.free_kick,
          bodyPart: marker.body_part,
          startX: marker.start_x,
          startY: marker.start_y,
          followsDribble: marker.follows_dribble,
          followsThroughBall: marker.follows_through_ball,
        }) as ShotMapEventMarker,
    );
