import { filtersToPersistAtom } from '@/atoms/filters/persistedFilters';
import {
  isLoadFilterSetModalOpenAtom,
  isSaveFilterModalOpenAtom,
  filterSetNameAtom,
  selectedFilterSetAtom,
} from '@/atoms/filters/userFilters';
import { filterSetsAtom } from '@/atoms/queries/userConfigs';
import { useUpsertFilterSetConfig } from '@/hooks/useUpsertFilterSetConfig';
import { UserConfigFilterDefinition } from '@/types/userConfigs';
import { Button, ButtonIcon, Menu, Flyout, Typography, useRect } from '@statsbomb/kitbag-components';
import { useTheme } from 'styled-components';
import { useAtomValue, useSetAtom } from 'jotai';
import { Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItemWithIcon } from '@/components/menu/MenuItemWithIcon';
import { ErrorBoundary } from '@sentry/react';
import { MoreOptionsMenu } from '@/components/menu/MoreOptionsMenu/MoreOptionsMenu';
import { useIsPage } from '@/hooks/useIsPage';
import { clearFiltersAtom } from '@/atoms/filters/resetFilters';
import { playerDataLockerFiltersToClear } from '@/consts/filters/playerDataLocker';

interface MenuItemProps {
  selectedFilterSet: UserConfigFilterDefinition;
  onClick: () => void;
}

const SaveMenuItem = ({ selectedFilterSet, onClick }: MenuItemProps) => {
  const { t } = useTranslation('general');
  const filtersToPersist = useAtomValue(filtersToPersistAtom);
  const upsertFilterSetConfig = useUpsertFilterSetConfig();

  return (
    <MenuItemWithIcon
      onClick={() => {
        upsertFilterSetConfig(selectedFilterSet.definition.name, filtersToPersist, selectedFilterSet.configId);
        onClick();
      }}
      icon="Save"
      text={t('save', { ns: 'general' })}
    />
  );
};

const SaveAsMenuItem = ({ selectedFilterSet, onClick }: MenuItemProps) => {
  const { t } = useTranslation('general');
  const setFilterSetName = useSetAtom(filterSetNameAtom);
  const setIsSaveModalOpen = useSetAtom(isSaveFilterModalOpenAtom);

  return (
    <MenuItemWithIcon
      onClick={() => {
        setFilterSetName(`${selectedFilterSet.definition.name} - ${t('copy')}`);
        setIsSaveModalOpen(true);
        onClick();
      }}
      icon="SaveAs"
      text={t('saveAsCopy', { ns: 'general' })}
    />
  );
};

const SaveFiltersButtonWithMenu = ({
  selectedFilterSet,
  isLoading,
}: {
  selectedFilterSet: UserConfigFilterDefinition;
  isLoading?: boolean;
}) => {
  const { t } = useTranslation('general');
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const theme = useTheme();
  const isTouch = theme.mqMatch('md');
  const [relativeTriggerRef, rect] = useRect<HTMLDivElement>(0);

  const closeMenu = () => setIsMenuOpen(false);

  /* 
    this is to close the menu when the browser is resized
    unfortunately KC doesn't handle this 
  */
  useEffect(closeMenu, [rect]);

  return (
    <div ref={relativeTriggerRef} data-testid={`menu-save-button${isLoading ? '-loading' : ''}`}>
      <ButtonIcon
        variant="secondary"
        displayText="left"
        icon="ChevronDown"
        onClick={() => setIsMenuOpen(true)}
        size="small"
        disabled={isLoading}
      >
        {t('save')}
      </ButtonIcon>
      {isMenuOpen && (
        <Flyout
          testId="user-filters-menu"
          triggerPosition={rect}
          placement="bottom-start"
          /* 125 moves the contents 125px to the left for mobile
          rather than coming off the side */
          offset={{ left: isTouch ? 125 : 0, top: 0 }}
        >
          <Menu onOutsideClick={closeMenu}>
            <SaveMenuItem selectedFilterSet={selectedFilterSet} onClick={closeMenu} />
            <SaveAsMenuItem selectedFilterSet={selectedFilterSet} onClick={closeMenu} />
          </Menu>
        </Flyout>
      )}
    </div>
  );
};

const UserFiltersButtonsBase = ({
  selectedFilterSet,
  isLoading,
  isError,
  hasFilterSets,
}: {
  selectedFilterSet: UserConfigFilterDefinition | null;
  isLoading?: boolean;
  isError?: boolean;
  hasFilterSets?: boolean;
}) => {
  const { t } = useTranslation(['general', 'filters']);
  const isPlayerDataLocker = useIsPage('data-locker/players');
  const clearFilters = useSetAtom(clearFiltersAtom);

  const filterSetName = selectedFilterSet ? selectedFilterSet.definition.name : t('filters.other');

  const setIsLoadModalOpen = useSetAtom(isLoadFilterSetModalOpenAtom);
  const setIsSaveModalOpen = useSetAtom(isSaveFilterModalOpenAtom);

  return (
    <div data-testid="user-filters-buttons" className="flex justify-between gap-2 p-4 items-center">
      <Typography variant="headingMedium" as="p" className="truncate" title={filterSetName}>
        {filterSetName}
      </Typography>
      <div className="flex gap-2 items-center">
        <Button
          onClick={() => setIsLoadModalOpen(true)}
          isDisabled={isLoading || isError || !hasFilterSets}
          size="small"
          variant="secondary"
        >
          {t('load')}
        </Button>
        {!selectedFilterSet ? (
          <Button
            variant="secondary"
            onClick={() => setIsSaveModalOpen(true)}
            size="small"
            isDisabled={isLoading || isError}
          >
            {t('save')}
          </Button>
        ) : (
          <SaveFiltersButtonWithMenu selectedFilterSet={selectedFilterSet} isLoading={isLoading} />
        )}
        {isPlayerDataLocker && (
          <MoreOptionsMenu>
            <MenuItemWithIcon
              icon="Erase"
              text={t('clearAllFilters', { ns: 'filters' })}
              onClick={() => clearFilters(playerDataLockerFiltersToClear)}
            />
          </MoreOptionsMenu>
        )}
      </div>
    </div>
  );
};

const UserFiltersButtonsWithData = () => {
  const filterSets = useAtomValue(filterSetsAtom);
  const selectedFilterSet = useAtomValue(selectedFilterSetAtom);
  return <UserFiltersButtonsBase selectedFilterSet={selectedFilterSet} hasFilterSets={filterSets.length > 0} />;
};

export const UserFiltersButtons = () => (
  <ErrorBoundary fallback={<UserFiltersButtonsBase selectedFilterSet={null} isError />}>
    <Suspense fallback={<UserFiltersButtonsBase selectedFilterSet={null} isLoading />}>
      <UserFiltersButtonsWithData />
    </Suspense>
  </ErrorBoundary>
);
