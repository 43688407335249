import { ButtonIcon } from '@statsbomb/kitbag-components';
import { useAtomValue } from 'jotai';
import { Dispatch, SetStateAction, Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { visibleTableColumnsAtom } from '@/atoms/table';
import { EditTableColumnsModal } from '../modal/EditTableColumnsModal/EditTableColumnsModal';

const EditColumnsButtonBase = ({
  disabled,
  visibleColumnsCount,
  setShowEditColumnsModal,
}: {
  disabled?: boolean;
  visibleColumnsCount?: number;
  setShowEditColumnsModal?: Dispatch<SetStateAction<boolean>>;
}) => {
  const { t } = useTranslation(['table']);

  return (
    <ButtonIcon
      size="small"
      icon="Visibility"
      displayText="right"
      variant="ghost"
      onClick={() => setShowEditColumnsModal && setShowEditColumnsModal(true)}
      disabled={disabled}
    >
      {visibleColumnsCount ? t('editColumnsWithCount', { count: visibleColumnsCount }) : t('editColumns')}
    </ButtonIcon>
  );
};

const EditColumnsButtonWithData = () => {
  const [showEditColumnsModal, setShowEditColumnsModal] = useState(false);
  const visibleColumnsCount = useAtomValue(visibleTableColumnsAtom).length;
  const isDisabled = visibleColumnsCount === 0;

  return (
    <>
      <EditColumnsButtonBase
        visibleColumnsCount={visibleColumnsCount}
        setShowEditColumnsModal={setShowEditColumnsModal}
        disabled={isDisabled}
      />
      {showEditColumnsModal && <EditTableColumnsModal setShowEditColumnsModal={setShowEditColumnsModal} />}
    </>
  );
};

export const EditColumnsButton = () => {
  const visibleTableColumnCount = useAtomValue(visibleTableColumnsAtom).length;

  return (
    <Suspense fallback={<EditColumnsButtonBase visibleColumnsCount={visibleTableColumnCount} disabled />}>
      <EditColumnsButtonWithData />
    </Suspense>
  );
};
