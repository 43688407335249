import { seasonOptionsAtom, selectedSeasonIdsAtom } from '@/atoms/filters/highLevel/seasons';
import { useAtom, useAtomValue } from 'jotai';
import { Suspense } from 'react';
import { useDeepEffect } from '@/hooks/useDeepEffect';
import { SeasonSelectorDropdown } from './SeasonSelectorDropdown';

const newSelectedIds = (persist: boolean, newSelectedSeasonIds: number[], optionValues: number[]) => {
  if (persist) {
    const hasSelectedSeasons = newSelectedSeasonIds.length > 0;

    /* if the currently selected season still exists in the list after the list of seasons changes
    then persist that season being selected, else select the first option in the list */
    return hasSelectedSeasons ? newSelectedSeasonIds : [optionValues[0]];
  }
  return newSelectedSeasonIds;
};

const SeasonSelectorWithData = ({ persist }: { persist: boolean }) => {
  const options = useAtomValue(seasonOptionsAtom);
  const [selectedSeasonIds, setSelectedSeasonIds] = useAtom(selectedSeasonIdsAtom);
  const optionValues = options.map(({ value }) => value);

  useDeepEffect(() => {
    if (selectedSeasonIds) {
      const newSelectedSeasonIds = selectedSeasonIds.filter(seasonId => optionValues.includes(seasonId));
      setSelectedSeasonIds(newSelectedIds(persist, newSelectedSeasonIds, optionValues));
    }
  }, [optionValues]);

  return <SeasonSelectorDropdown options={options} isMulti={!persist} />;
};

export const SeasonSelector = ({ persist = false }: { persist?: boolean }) => (
  <Suspense fallback={<SeasonSelectorDropdown isLoading />}>
    <SeasonSelectorWithData persist={persist} />
  </Suspense>
);
