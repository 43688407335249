import { TEAM_AGGS_FILTER_ATOMS } from '@/consts/dataLocker/team';
import { useSetDataLockerDebouncedFilterParams } from '@/hooks/apiFilterParams/useSetDataLockerDebouncedFilterParams';
import { UserFilters } from '../filters/UserFilters/UserFilters';
import { DataLockerTeamHighLevelFilters } from './filters/DataLockerTeamHighLevelFilters';

const DebouncedFilterParams = () => {
  useSetDataLockerDebouncedFilterParams(TEAM_AGGS_FILTER_ATOMS);
  return null;
};

export const TeamAggsFilters = () => (
  <div className="flex flex-col">
    <DebouncedFilterParams />
    <UserFilters />
    <DataLockerTeamHighLevelFilters />
  </div>
);
