export enum StatusTranslationKey {
  ERROR = 'error',
  NO_DATA = 'noData',
  LOADING = 'loading',
  INFO = 'info',
}

// This enum is not SHOUT_CASE because it needs to map to the same keys we use in the translation file (camelCase)
export enum StatusType {
  error = 'error',
  noData = 'info',
  loading = 'loading',
  info = 'info',
}

export type Status = {
  statusType: StatusType;
  statusTitle: string;
  statusDescription: string;
};
